.maincontainer{
    background: white;
    border: 1px solid #099137;
    border-radius: 16px;
    padding: 20px;
    color: black;
    height: 100%;
    text-align: left;
}
.maincontainer h5{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
}
.maincontainer h6 {
    font-weight: 400;
    font-size: 12px;
    line-height: 17.4px;
    margin: 0px;
}
.maincontainer .forstatus{
    color: aquamarine;
    font-weight: 500;
    cursor: pointer;
}
.maincontainer p{
    margin: 5px;
}