.border {
    border: 1px solid #E4E7EC;
    border-radius: 8px;
}
.color{
    color: #0065FF;
    font-weight: 500;
    cursor: pointer;
}
input {
    background: #F0F2F5;
}