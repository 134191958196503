.white{
    color:white;
}
.black900{
    color:#101928
}
.black800{
    color:#1D2739
}
.black700{
    color:#344054
}
.black600{
    color:#475367
}
.black500{
    color:#667185
}
.black400{
    color:#98A2B3
}
.black300{
    color:#D0D5DD
}
.black200{
    color:#E4E7EC
}
.black100{
    color:#F0F2F5
}
.black75{
    color:#F7F9FC
}
.black50{
    color:#F9FAFB
}
.brown900{
    color: #3E3838
}
.brown800{
    color: #514A4A
}
.brown700{
    color: #645D5D
}
.brown600{
    color: #787070
}
.brown500{
    color: #8D8484
}
.brown400{
    color: #A29999
}
.brown300{
    color: #B7AFAF
}
.brown200{
    color: #CDC4C4
}
.brown100{
    color: #E4DBDB
}
.brown75{
    color: #F0E6E6
}
.brown50{
    color: #FBF1F1
}
.app-primary{
    color: #0065FF
}
.primary500{
    color: #003E9C;
}
.app-secondary{
    color: #6E6E6E;
}
.bg-black900{
    background-color:#101928
}
.bg-black800{
    background-color:#1D2739
}
.bg-black700{
    background-color:#344054
}
.bg-black600{
    background-color:#475367
}
.bg-black500{
    background-color:#667185
}
.bg-black400{
    background-color:#98A2B3
}
.bg-black300{
    background-color:#D0D5DD
}
.bg-black200{
    background-color:#E4E7EC
}
.bg-black100{
    background-color:#F0F2F5
}
.bg-black75{
    background-color:#F7F9FC
}
.bg-black50{
    background-color:#F9FAFB
}
.bg-brown900{
    background-color: #3E3838
}
.bg-brown800{
    background-color: #514A4A
}
.bg-brown700{
    background-color: #645D5D
}
.bg-brown600{
    background-color: #787070
}
.bg-brown500{
    background-color: #8D8484
}
.bg-brown400{
    background-color: #A29999
}
.bg-brown300{
    background-color: #B7AFAF
}
.bg-brown200{
    background-color: #CDC4C4
}
.bg-brown100{
    background-color: #E4DBDB
}
.bg-brown75{
    background-color: #F0E6E6
}
.bg-brown50{
    background-color: #FBF1F1
}
.bg-app-primary{
    background-color: #0065FF;
}
.bg-primary500{
    background-color: #003E9C;
}
.bg-app-secondary{
    background-color: #6E6E6E;
}
.bg-app-danger{
    background-color: #C1231D;
}
.bg-white{
    background-color: white;
}