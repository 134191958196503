/* STYLING FOR MODAL */
.modalpopup{
    width: 620px;
    max-width: 100%;
    position: relative;
}
.modalinfo{
    margin-block: 0rem 2rem;
    margin-inline: 2rem;
    position: relative;
}
.css153eyq5MuiModalrootMuiDialogroot .MuiDialogContentroot {
    padding: 0px!important;
}
.modaltitle{
    color: #1A1A21;
    font-size: 18px;
    margin-bottom: 0px
}
.sticky {
    position: sticky;
    top: 0;
    padding-block: 2rem 0px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;
}
.texttag{
    color: rgba(102, 113, 133, 1);
    font-size: 16px !important;
    margin-bottom: 1rem !important;
    letter-spacing: -0.5px;
}
.addclassheader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: -moz-hidden-unscrollable;
    /* margin-bottom: 10px; */
    position: relative
}
.addclassheader h4{
    letter-spacing: -0.3px;
}
.css1t1j96hMuiPaperrootMuiDialogpaper{
    width: 100%;
    padding: 0px !important;
    margin-bottom: 32px !important;
    border-bottom: none !important;
    height: fit-content !important;
}

@media (max-width: 430px) {
    .extrasubject{
        flex-direction: column;
        gap: 0px;
    }
    .texttag{
        font-size: 14px !important;
    }
    .modalinfo {
        margin-inline: 1rem;
    }
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        margin: 16px !important;
    }
    .css-mhc70k-MuiGrid-root > .MuiGrid-item {
        padding-left: 8px;
    }
    .sticky {
        padding-block: 1rem 0px;
    }
}