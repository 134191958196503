.template{
    gap: 1rem;
    padding: 2rem;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 0 !important;
}
.preview .header img{
    width: 8rem !important;
}

.token{
    background: white;
    width: fit-content;
    display: flex;
    align-self: flex-start;
    padding: 0.5rem 2rem;
    border-radius: 6px;
align-items: center;
margin-bottom: 2rem;
}

.token h3{
    margin: 0;
    padding-left: 2rem;
    font-weight: 400;
    color: #0065FF;
    font-size: 1.3rem;
}

.template_border{
    border: 1px solid #E1E5ED;
    padding: 0.8rem;
    border-radius: 6px;
    margin: 1rem;
    margin-bottom: 0 !important;
    display: grid;
    grid-template-columns: 1fr;

}

.template_border .check{
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.no_margin{
    margin-top: -16px;
}
.padding-white{
    margin-top: 0 !important;
}

.preview .content{
    margin-bottom: 1rem;
}
.padding_1rem {
    padding: 1rem;
}

.preview{
    background: #F0F2F5;
    padding: 0.5rem;
    border-radius: 8px;
}

.main_preview{
    background: white;
    padding: 10px;
}
.preview .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-bottom: 1px solid #6C737F;
}

.preview .header img{
    width: 4rem !important;
}
.preview .footer{
    border-top: 1px solid #6C737F;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;

}
.preview .footer .icon{
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: center;
}

.preview .icon div{
    background: #F9F9F9;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
}

.preview .footer img{
    height: 1rem;
}

.main_preview > p{
    margin-top: 10px;
}