.App {
  text-align: center;
  font-family: 'Trip Sans';
}

.not_found{
  max-width: 300px;
  margin: auto;
  align-self: center;
}

.profile_icon{
  z-index: 5000 !important;
}
.error_message {
  color: red;
  font-size: 12px;
  padding: 0 10px;
}


.payment_div{
  width:100%;
  padding-left: 1rem
}
.custom_Button{
  margin-top: 10px;
}

.action_div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100% !important;
}

.action_div .search_container{
  display: flex;
  align-items: center;
  width: 283px;
}
.action_div select{
  height: 40px !important;
  margin-bottom: 5.5px;
}
.upload_div{
  display: grid;
  grid-template-columns: 1.7fr 0.3fr;
  gap: 2rem;
  align-items: center;
  width: 100% !important;
}
.upload_div input{
  width: 100% !important;
}

.displaypic{
  width: inherit;
  height: inherit;
  border-radius: 50%;
  padding: 0.5rem;
}
.textarea{
  width: 100%;
  height: 5rem;
  resize: none;
}

.class_div{
  background: #0065ff;
  color: white;
  padding: 10px;
  border-radius: 45% !important;
  margin-right: 5px !important;
  height: fit-content;
  width: fit-content;
}


/* CHAT SECTION styling Application DEtails and Support */
.upload_p{
  cursor: pointer;
  padding-left: 1rem;
  text-decoration: underline;
  font-weight: 700;
}
.upload_comment{
  color: #099137;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  cursor: pointer;
}
.no_comments{
  padding-top: 0.8rem !important;
  margin: 0 !important;
}
.comment_div{
  max-height: 400px;
  min-height: 150px;
  overflow-y: auto;
}
.admin_comment{
  width: 100%;
  display: flex;
  margin: 2.5px 0 !important;
    flex-direction: column;
 
}
.applicant{
  align-items: flex-end;
}
.user{
  align-items: flex-end;
}
.comment_main{
  max-width: 70%; 
  min-width: 250px;
  display: flex;
  flex-direction: column;
}

/* For Lesson Note */
.lesson_note_admin .comment_main {
  margin-left: auto;
}
.applicant .comment_container{
  align-items: flex-end;
  display: flex;
  flex-direction: column;


}
.user .comment_container{
  align-items: flex-end;
  display: flex;
  flex-direction: column;


}

.admin .comment_container{
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: fit-content;

}
.super_admin .comment_container{
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: fit-content;

}

.super_admin .admin_comment_span{
  padding: 8px 1rem;
  background-color: #eaeaea;
    border-radius: 10px;
    border-top-left-radius: 0 !important;
    margin-bottom: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    text-align: left;
}
.admin_comment_span {
  display: block;
  width: fit-content;
  text-align: right;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.comment_span {
  display: block;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.admin .admin_comment_span{
  padding: 8px 1rem;
  background-color: #eaeaea;
    border-radius: 10px;
    border-top-left-radius: 0 !important;
    margin-bottom: 10px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
}


.applicant .admin_comment_span{
  padding: 8px 1rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
}
.user .admin_comment_span{
  padding: 8px 1rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
}
.reply_text{
  justify-content: flex-end;
}
/* .admin_comment_p{
  display: flex;
  max-width:70%;
  flex-direction: column;
  min-width: 250px;
} */
.admin_comment_p> div{
  display: flex;
  flex-direction: column;
}

.selected-comment-to-reply {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  background: rgba(109, 109, 109, 0.224);
  padding: 0.5rem;
  margin-top: 10px;
}

.selected-comment-to-reply >div span{
  font-size: 10px;
  font-weight: 600;
}

.selected-comment-to-reply >div p{
  font-size: 14px;
}


.btn_padding{
  padding: 10px 1rem!important;
  border: 1px solid #0065FF;
  border-radius: 8px;
  background: white;
  color: #0065FF;
  margin-top: 14px;
}

.justify_end{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.justify_start{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.reply_container_super_admin .reply_span {
  display: flex;
  justify-content: flex-start;
}
.reply_container_user .reply_span {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.applicant_comment{
display: flex;
flex-direction: column;
width: 65%;
float: right;
    
    
}
.user_comment{
display: flex;
flex-direction: column;
width: 65%;
float: right;
    
    
}

.relative-full {
  position: relative;
  width: 100%;
}

.banner_div{
  position: relative;
}
.edit_icon{
  background: #cb1a14;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  color: white;
  border-radius: 40px;
  padding: 8px 1rem;
  top: 80px;
  right: 50px;
  
}
.remove_btn{
  background: red;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  border-radius: 20px;
  padding: 8px 1rem;
  height: fit-content;

  
}
.admin_comment p{
  font-size: 10px;
  font-weight: 500;
}
.comment_time{
  font-size: 10px;
}
.reply{
  font-weight: 500;
  font-size: 10px;
  cursor: pointer;
  align-self: flex-end;
}
.cancel_reply{
  width: fit-content;
}
.reply_div{
  width: 100%;
  display: flex;
  flex-direction: column;

}

.reply_div .admin_comment_span{
  width: 100%;
  border-radius: 0 !important;
}

.reply_div .cancel_reply{
  background: transparent;
  border: 0;
  font-size: 1.5rem;
  cursor: pointer;
}
.reply-container_admin{
  margin-top: 8px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.reply-container_admin .reply_main{
  max-width: 70%;

}
.reply-container_admin .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373);
  padding: 5px;
  border-radius: 5px;

}
.reply-container_user .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373);
  padding: 5px;
  border-radius: 5px;

}
.reply-container_super_admin .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373);
  padding: 5px;
  border-radius: 5px;

}
.reply-container_super_admin .original-comment{
  padding: 8px 0.8rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
}
.reply-container_user .original-comment{
  padding: 8px 0.8rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
}
.reply-container_admin .original-comment{
  padding: 8px 0.8rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
}
.reply-container_super_admin .original-comment{
  padding: 8px 0.8rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
}

.reply-container_super_admin{
  margin-top: 8px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reply-container_user{
  margin-top: 8px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reply-container_applicant{
  margin-top: 8px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reply-container_user{
  margin-top: 8px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reply-container_applicant .reply_main{
  max-width: 70%;

}
.reply-container_user .reply_main{
  max-width: 70%;

}
.reply-container_applicant .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373) !important;
  padding: 5px;
  border-radius: 5px;

}
.reply-container_user .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373) !important;
  padding: 5px;
  border-radius: 5px;

}
.reply-container_applicant .original-comment{
  padding: 8px 0.8rem;
  background-color: #eaeaea;
  border-radius: 10px;
  border-top-left-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
}
.reply-container_user .original-comment{
  padding: 8px 0.8rem;
  background-color: #eaeaea;
  border-radius: 10px;
  border-top-left-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
}

/* APPLICATION DETAILS COMMENT STYLING */

.admin .apply .admin_comment_span{
  background-color: rgb(197, 240, 254) !important;
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 10px !important;
}

.applicant .apply .admin_comment_span{
  background-color: #eaeaea !important;
  
}

.application_comment .applicant{
  align-items: flex-start;
}

.application_comment .admin{
  align-items: flex-end;
}

.applicant .apply{
  align-self: flex-start !important;
}
.admin .apply{
  align-self: flex-end !important;
}


 /* APPLICATION REPLY SECTION STYLING */
.reply_container_applicant{
  margin-top: 10px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.reply_container_applicant .reply_main{
  max-width: 70% !important;

}
.reply_container_applicant .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373);
  padding: 5px;
  border-radius: 5px;

}
.reply_container_applicant .original-comment{
  padding: 8px 0.8rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
  width: fit-content !important;
}
/* .reply_container_super_admin .original-comment{
  padding: 8px 0.8rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
  width: fit-content !important;
} */

.reply_container_admin{
  margin-top: 10px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reply_container_super_admin{
  margin-top: 10px 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.reply_container_admin .reply_main{
  max-width: 70% !important;

}
.all-chat-height {
  height: 400px;
  overflow: hidden;
  padding-bottom: 40px;
}
.reply_container_admin .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373) !important;
  padding: 5px;
  border-radius: 5px;

}
.reply_container_super_admin .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373) !important;
  padding: 5px;
  border-radius: 5px;

}
.reply_container_user .original-comment .reply_com{
  background-color: rgba(255, 255, 255, 0.373) !important;
  padding: 5px;
  border-radius: 5px;

}
.reply_container_admin .original-comment{
  padding: 8px 0.8rem;
  background-color: #eaeaea;
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
  width: fit-content !important;

}
.reply_container_super_admin .original-comment{
  padding: 8px 0.8rem;
  background-color: #eaeaea;
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
  width: fit-content !important;

}
.reply_container_user .original-comment{
  padding: 8px 0.8rem;
  background-color: rgb(197, 240, 254);
  border-radius: 10px;
  border-top-right-radius: 0 !important;
  display: flex;
  font-size: 14px;
 flex-direction: column;
  font-weight: 400;
  max-width: 100%;
  margin-left: auto;

}
/* APPLICATION COMMENT DETAILS STYLING */

.no_upload{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.gaurdian_input{
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid hsl(0, 0%, 80%);
  margin-top: 4px;
  border-radius: 6px;
  background-color: white;
  color: #667185;
  display: flex;
  align-items: center;
}
.gaurdian_input input{
  width: 100%;
  height: 35px;
  border: 0;
  background: transparent;
  outline: none;
}

.select_flow{
  z-index: 5000 !important;
}

.main_site_map{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
}


.site_map p{
  text-align: left;
}

@media screen and (max-width: 945px) {
.main_site_map{
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
  
}

@media screen and (max-width: 700px) {
  .main_site_map{
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    
  }
  
}

@media screen and (max-width: 380px) {
  .main_site_map{
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
}

@font-face {
  font-family: 'Trip Sans';
  src: local('Trip Sans Regular'), local('Trip-Sans-Regular'),
      url('../assets/fonts/TripSans-Regular.woff2') format('woff2'),
      url('../assets/fonts/TripSans-Regular.woff') format('woff'),
      url('../assets/fonts/TripSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Trip Sans';
  src: local('Trip Sans Medium'), local('Trip-Sans-Medium'),
      url('../assets//fonts/TripSans-Medium.woff2') format('woff2'),
      url('../assets//fonts/TripSans-Medium.woff') format('woff'),
      url('../assets//fonts/TripSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Trip Sans';
  src: local('Trip Sans Bold'), local('Trip-Sans-Bold'),
      url('../assets//fonts/TripSans-Bold.woff2') format('woff2'),
      url('../assets//fonts/TripSans-Bold.woff') format('woff'),
      url('../assets//fonts/TripSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  /* text-transform: ; */
}

.attend_css .css-46bh2p-MuiCardContent-root {
  padding: 0 !important;
}

.scroll-x{
  overflow-x: scroll !important;
}

/* .float_right{
  display: flex;
  flex-direction: column;
  float: right ;
} */

.flex {
  display: flex;
  align-items: center;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content {
  background-color: white;
  width: 100%;
  min-height: 80%;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  padding-block: 10px;
  padding-inline: 16px;
  position: relative;
}
.page-content-mh-83 {
  background-color: white;
  width: 100%;
  min-height: 83%;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  padding-block: 10px;
  padding-inline: 16px;
  position: relative;
}

.gap-10 {
  gap: 10px;
}
.gap-16 {
  gap: 16px;
}

.gap-24{
  gap: 24px;
}
.gap-5{
  gap: 5px;
}
.input-required {
  color: #DD514D;
  font-size: 16px;
  margin-left: 3px;
}
.text-white {
  color: #FFF;
}

.text-danger {
  color: #9E0A05;
  font-size: 12px;
}

.position-relative{
  position: relative;
}

.create_assignment_content{
  margin: 30px;
}

.font-800 {
  font-weight: 800;
}

.key-pair-container {
  background-color: white;
  border-radius: 6px;
  width: 100%;
  padding: 15px;
  border: 1px solid #E4E7EC
}

.key-pair-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.key-pair-name {
  font-size: 14px;
  color: #667185;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.key-pair-value {
  font-size: 15px;
  color: #101928;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: right;
}

.center {
  text-align: center;
}

.align-items-start{
  align-items: flex-start;
}

.margin-right-10 {
  margin-right: 10px;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-self-end {
  align-self: self-end;
}

.justify-items-center {
  justify-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-items-space{
  justify-content: space-between;
  width: 100%;
}

.margin-left-auto {
  margin-left: auto;
}
.justify-right-flex{
  display: flex;
  justify-content: right;
}
.justify-left-flex{
  display: flex;
  justify-content: left;
}
.width-8 {
  width: 8px;
}
.width-full {
  width: 100%;
}
.justify-self-end {
  justify-self: flex-end;
}
.justify-item-right {
  justify-content: right;
}
.justify-item-left {
  justify-content: left;
  width: 100%;
}
.margin-right{
  margin-right: auto;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-16 {
  margin-top: 16px;
}
.margin-top-8 {
  margin-top: 16px;
}
.margin-block-3 {
  margin-block: 3px;
}

.margin-inline-20 {
  margin-inline: 20px
}
.no_padding_0 {
  padding: 0px !important;
}
.margin-bottom-14 {
  margin-bottom: 14px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.padding-top-30 {
  padding-top: 30px;
}
.margin-auto {
  margin: auto;
}

.half-width {
  width: 70%;
}
.height-200 {
  height: 200px;
}
.width-full {
  width: 100%;
}
/* Style Search Icon in Table Search */
.guardian_svg svg {
  left: 45px !important;
  margin-top: 12px;
}
.text-align-auto {
  text-align: right;
}

.delete-input {
  gap: 2px;
  color: #DD514D;
  font-weight: 500;
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  cursor: pointer
}

/* Success Response Page */
.pay-redirect{
  background: white;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pay-redirect div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pay-redirect div p{
  margin: 0 !important;
font-size: 16px;
font-weight: 500;


}

.pay-redirect a{
  padding: 10px 2rem;
  background: #0065FF;
  color: white;
  border-radius: 32px;
  text-decoration: none;
  margin-top: 1rem;
}

.delete-grade-input {
  gap: 2px;
  color: #DD514D;
  font-weight: 500;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer
}
/* .padding-left-modal{
  padding-left: 3%;
  margin-block: 21px;
} */

.padding-equal {
  padding-block: 24px;
  padding-inline: 24px;
}
.link-teacher a {
  text-decoration: none;
  color: #000;
}
.input-picker-title {
  color: #101928;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

/* Styling for events */
.approved-events {
  background-color: #0f973d;
  border-radius: 8px;
  border: 1px solid #91cfa6;
  border-left: 3px solid #91cfa6
}

.general-events {
  background-color: #0065FF;
  border-radius: 8px;
  border: 1px solid #6BA6FF;
  border-left: 3px solid #6BA6FF
}

.pending-events {
  background-color: #F3A218;
  border-radius: 8px;
  border: 1px solid #F9D495;
  border-left: 3px solid #F9D495
}

.declined-events {
  background-color: #D42620;
  border-radius: 8px;
  border: 1px solid #EB9B98;
  border-left: 3px solid #EB9B98
}

.approved-text {
  color: #0f973d
}
/* Styling for question preview, suitable for assignments, tests and Exams */
.question-preview-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  border-radius: 6px;
  padding: 16px;
  border: 1px solid #ccc;
  /* width: 300px; */
  text-align: left
}

.question-preview-heading {
color: #40444B;
font-size: 16px;
font-weight: 500;
letter-spacing: -0.6px;
margin-block: 5px;
}

.options-preview {
  display: flex;
  gap: 4px;
  color: black;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* general styling to overide material ui styles */

.tablist-container {
  margin-top: 24px;
}

.checkbox-label {
  font-weight: 500;
  font-size: 14px;
}

.multiple-select {
  margin-top: 9px;
}

.multiple-select p {
  color: #101928;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  text-align: left;
}
.css-d9nlrv-MuiTabPanel-root {
  height: 60vh;
}
.multiple-select .css-13cymwt-control {
  text-align: left;
  width: 100%;
  min-height: 46px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: white;
  color: #667185;
}

.input-padding {
  padding-top: 15px;
}

.padding-white {
  height: fit-content !important;
  background: white;
  padding: 0.8rem;
  border-radius: 6px;
}


.tabpanel-overide {
  padding: 24px 0px 0px 0px !important;
}

.reduce-padding-left {
  padding-left: 20px;
}

.padding-left-16 {
  padding-left: 16px;
}

.padding-block-16 {
  padding-block: 16px;
}

.tablist-container div {
  padding: 0px!important;
  overflow: scroll;
}

.table-action-container {
  display: flex;
  gap: 6px;
}

.table-action-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%
}

.modal-name-highlight {
  font-weight: bold;
}

.overide-tablist {
  width: fit-content!important;
  border: 1px solid #E4E7EC !important;
  border-radius: 6px
}

.tablist-container button {
  text-transform: none;
  border-right: 1px solid #E4E7EC;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 14px;
}

.padding-inline{
  padding-inline: 20px;
}

.tablist-container button:last-child {
  border-right: none;
}

.tablist-container button:focus {
  background-color: #F0F2F5;
  color: "#1D2739"
}

.container-border {
  border: 2px solid #F7F7F7;
}

.ticket-closed {
  width: 100%;
  background-color: #FEF6E8;
  padding: 10px;
  border: 0.84px solid #F0F2F5;
  margin-block: 10px;
}

.left-container {
  background-color: white;
  height: calc(100vh - 300px);
  box-sizing: border-box;
  padding: 24px 15px;
  overflow-y: scroll;
}

.left-container h4 {
  color: #101928;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 4px;
}
.font-size-12 {
  font-size: 12px;
}
.left-container h6 {
  color: #667185;
  letter-spacing: -0.4px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  margin-top: 0px;
}

.right-container {
  background-color: white;
  height: calc(100vh - 300px);
  box-sizing: border-box;
  padding: 24px 15px;
  overflow-y:scroll;
}

.cover-page {
  padding: 16px;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  box-sizing: border-box;
  background-color: white;
  margin-top: 20px;
}

.container-y-overflow {
  margin-top: 16px;
  height: calc(100vh - 300px);
  box-sizing: border-box;
  overflow-y: scroll;
}

ul {
  list-style: none;
  padding: 0px;
}
.justifyRight {
  justify-content: flex-end;
}

/* For Font */
.fontsize-14 {
  font-size: 14px;
}
.fontweight-500 {
  font-weight: 500;
}

.height-full {
  height: 100vh;
}

.tab-left {
  -webkit-align-items: unset;
  align-items: unset;
}

.margin-top{
  margin-top: 20px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-12 {
  margin-top: 12px;
}
.margin-top-13 {
  margin-top: 13.38px;
}
.margin-top-30 {
  margin-top: 30.38px;
}
.margin-bottom{
  margin-bottom: 20px;
}
.block{
  display: block;
}

.full-width {
  width: 100%;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-right-imp {
  text-align: right !important;
}
.soft-border {
  border: 1px solid #F0F2F5;
  border-radius: 9.5px;
  min-width: 80px;
  padding: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.table-border {
  margin-top: 20px;
  border: 1px solid #E4E7EC;
  border-radius: 9.5px;
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.table-border .datatable header>div:last-child {
  width: 55% !important;
}

.height300 {
  height: 300px;
}

.margin-left{
  margin-left: 20px;
}
.margin-left-16{
  margin-left: 16px;
}
.align-items-end {
  align-items: flex-end;
}
.no-margin-block {
  margin-block: 0px;
}
.margin-left-more{
  margin-left: 50px;
}
.padding-top-56 {
  margin-top: 56px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-18 {
  margin-bottom: 18px;
}
.margin-bottom{
  margin-bottom: 20px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-auto {
  margin: auto;
}
.margin-left {
  margin-left: auto;
}
.no_margin{
  margin: 0 !important;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-left-24 {
  padding-left: 24px;
}
.padding-inline-10 {
  padding-inline: 10px;
}
.paddingup_1{
  margin: 1rem 0 !important;
}
.padding-16{
  padding:1rem;
  height: 100%;
}
.overflow_x{
  overflow-x: scroll !important;
}
/* Circular Progress Bar */
.CircularProgressbar-background {
  fill: #F0F2F5 !important;
}
/* Payment */
.payment-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 16px;
}

.payment-table th,
.payment-table td {
  padding: 15px;
}

.payment-table th {
  background-color: #F9FAFB;
}

.payment-method {
  display: inline-block;
  margin-right: 5px;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.payment-method.paystack {
  background-color: #007bff;
}

.payment-method.flutterwave {
  background-color: #ffa500;
}

.amount-cell {
  display: flex;
  align-items: center;
  gap: 5px;
}

.amount {
  font-weight: bold;
}

.currency {
  color: #888;
  font-size: 16px;
  background-color: #F5F6F7;
  padding: 12.5px 20px;
  border-radius: 6px;
  margin-left: 10px;
}


/* Admin TimeLine */

.breadcrumb-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.pagination-container {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid rgba(0, 0, 0, .12);
  background-color: white;
  justify-content: center;
  margin-top:40px
}

.cursor-pointer {
  cursor: pointer;
}

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 10px !important;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 16px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.table-action-icon {
  background-color: transparent;
  border: none;
  color: #514A4A;
}

.table-action-icon :hover{
  cursor: pointer;
}

.table-action-icon svg {
  font-size: 20px
}

.css-i4bv87-MuiSvgIcon-root{
  font-size: 16px!important
}

.delete-icon {
  color: #DD514D !important;
  font-size: 13px;
  font-weight: 500
}
.delete-icon :hover {
  cursor: pointer;
}
.approve-icon {
  color: green !important;
  font-size: 13px;
  font-weight: 500
}
.approve-icon :hover {
  cursor: pointer;
}
.Blockspacing{
  margin-top: 16px;
}
.button-count-container {
  margin-top: 15px;
  margin-bottom: 15px;
  /* padding-left: 10px; */
}
.additional-input {
  /* padding-left: 3%; */
  gap: 2px;
  color: #667185;
  font-weight: 500;
}

.additional-input :hover{
  cursor: pointer
}

.ql-editor{
  padding: 30px !important;
}

/* Calender Restyle */
.fc .fc-toolbar-title {
  font-size: 14px;
}

.custom_addButton{
  display: flex !important;
  margin: 1.5rem;
  gap: 1rem;
}
.subtitle-container {
  max-width: 100px;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
}

.rotated-vertical {
  transform: none;
  /* transform: rotate(-90deg);
  padding: 0;
  max-width: "45px";
  text-align: center; */
  /* transform-origin: left top;
  white-space: nowrap; */
}
/* .rotated-vertical p {
  margin-block: 40px 0px;
} */

.normal-horizontal {
  transform: none;
}

.custom_div{
  display: flex !important;
  gap: 1rem;
  width: 100%;
  align-items: flex-end;
}
.container-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mobile-styling {
  margin: 0;
  font-size: 12px;
  color: #667185;
}

.responsive-player {
  width: 100%;
  height: 300px; /* Default height */
}

@media (max-width: 550px) {
  .responsive-player {
    height: 100%; /* Full height for small screens */
  }
}


/* For Checked Attendance */
.css-12g0btw-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px 9px 6px 9px;
}
.no_upload_centering {
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
/* .fc-daygrid-day-frame .fc-scrollgrid-sync-inner {
  max-width: 150px;
  height: 100px;
} */

/* .fc-col-header-cell {
  max-width: 150px;
} */
 /* For Steps */
 .css-n7tliy-MuiStep-root {
  max-width: 300px;
 }

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 34px !important;
}
.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  display: none;
}

/* Add a thumb */
::-webkit-scrollbar-thumb {
  background: #000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 1200px) {
  .table-search-wrapper {
      width: 35%;
  }
}

@media(max-width: 768px){
  .left-container {
    height: 100%;
  }
  .breadcrumb-container {
    gap: 10px
  }
  .tablist-container {
    overflow: scroll
  }
  .applicant_comment{
    width: 100%;
  }
}
@media(max-width: 900px){
  .flex-col-reverse{
    display: flex;
    flex-direction: column-reverse;
    width: inherit !important;
  }
  .hide-900{
    display: none ;
  }
  .view_900{
    display: block;
  }
  .custom_div{
    display: flex;
    align-items: flex-end;
    margin-bottom: 1rem;
  }
  .custom_Button{
    margin-bottom: 1rem;
  }


}
@media (max-width: 1135px) {
  .css-d9nlrv-MuiTabPanel-root {
    overflow: scroll;
    height: 48vh;
  }
}
@media(max-width: 700px) {
  .flex-wrap-items {
    flex-wrap: wrap;
  }
}
@media (max-width: 413px) {
  .flex-wrap-items-413 {
    flex-wrap: wrap;
    flex: 1;
  }
  .flex-item-reverse-413 {
    flex-direction: row-reverse;
  }
  .text-align-auto {
    text-align: left;
  }
  .text-align-left-413 {
    text-align: left;
  }
  .padding-block-413 {
    padding: 10px 0px !important;
  }
  .flex-direction-col-413 {
    flex-direction: column;
    align-items: center;
  }
  .button_tab{
    flex-wrap: wrap;
    display: flex;
  }
}
@media (max-width: 600px) {
  .no_left{
    padding-left:  0 !important;
  }
  .scroll-x{
    border: none !important;
    padding: 0!important;
  }
  .attendace_mobile{
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .white_bg{
    background: white !important;
    padding: 10px !important;
    
  }
  .css-13xfq8m-MuiTabPanel-root {
    padding: 0!important;
  }

  .flex-wrap-items-600 {
    flex-wrap: wrap;
    flex: 1;
  }
  .class_div {
    padding: 2px 10px;
    border-radius: 20% !important;
    margin-top: 5px;
  }
  .margin-top-10-v600 {
    margin-top: 10px;
  }
  .tablist-container {
    margin-bottom: 8px;
  }
  .padding-left-16 {
    padding-left: 0px;
  }
  .page-content{
    background: none;
    padding-left: 0;
    padding-right: 0;
  }

  .rdt_TableRow{
    padding: 0 !important;
  }
  .margin_tab{
    margin-bottom: 8px !important;
  }
  .margin-no-top{
    margin-top: 0 !important;
  }
  .datatable header{
    padding-left: 0 !important;
    
  }

  .search_container svg{
    margin-left: -8px !important;
  }

}
@media (max-width: 426px) {
  .flex-wrap-items-426 {
    flex-wrap: wrap;
    flex: 1;
  }
  .margin-top-16-items-426 {
    margin-top: 16px;
  }

  .btn-margin-top-10 {
    margin-top: 10px;
  }
}
@media screen and (max-width: 430px) {
  .flex-wrap-items-v430 {
    flex-wrap: wrap;
  }
  .page-content {
    padding-inline: 3px;
  }
  .guardian_svg svg {
    left: 25px !important;
    margin-top: 12px;
  }
}