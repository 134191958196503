/* This auth css file would be used for the login, sign up, forgot password component and Verificationsuccess page*/

.auth-wrapper {
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #F5F6F7;
    padding: 5%;
    gap: 6%
}

.auth-container {
    width: 1366px;
    margin: auto;
}

.auth-bg-container {
    width: 627px;
    height: 760px;
    overflow: hidden;
}

.auth-form-container {
    width: 516px;
    /* height: 85%; */
    border-radius: 10px;
    padding: 42px 38px;
    justify-content: center;
    background-color: white;
    box-sizing: border-box;
    position: relative;
}

.auth-flex {
    display: flex;
    align-items: center;
    gap: 82px;
}

.auth-bg-img {
    width: 100%;
}


.auth-logo-container {
    text-align: left;
}

.auth-logo {
    width: 88;
    height: 28;
    text-align: left;
    margin-bottom: 16px;
}


.logotypeText {
    color: "white";
    font-weight: 500;
    font-size: 84;
    /* [theme.breakpoints.down("md")]: {
      fontSize: 48;
    }; */
}

.auth-form {
    width: 100%;
}

.auth-heading{
    font-size: 20px;
    letter-spacing: -2%;
    text-align: left;
    margin-bottom: 6px;
    font-weight: 500;
}

.auth-subheading {
    font-size: 16px;
    text-align: left;
    line-height: 17px;
    letter-spacing: -2%;
    width: 90%;
    font-weight: 400;
}

.verfication-container {
    padding-top: 5%
}

.auth-create-button{
    height: 54px;
    width: 100%;
    margin-top: 20px;
    border-radius: 27px;
    text-transform: "none";
    border-color: transparent;
    outline: 0;
    font-size: 14px;
    letter-spacing: -2%;
}

.error-message {
    text-align: "center";
}

.auth-form-buttons{
    width: 100%;
    margin-top: 40px;
}

.auth-divider{
    position: relative;
    margin-top:40px;
    margin-bottom: 40px;
}

.auth-divider span{
    text-align: center;
    background-color: white;
    position: absolute;
    z-index: 3;
    top:-18px;
    font-size: 18px;
    padding: 5px;
}

.auth-divider hr {
    margin: 0px;
}

.auth-forget-button {
    text-transform: none;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    margin-top: 8px;
    display: inline-block;
    letter-spacing: -0.5px;
}

.auth-account-button {
    font-size: 18px;
    letter-spacing: -0.5px;
}

.auth-account-button a{
    padding-left: 3px;
    font-weight: 500;
    text-decoration: none;
}

.password-eye {
    position: relative;
	top: -32px;
	left: 45%;
}
.passwords-eye {
    width: 26px;
    top: -32px;
    position: relative;
    margin-left: auto;
}
.password-eye-90 {
    position: relative;
	top: -32px;
	left: 90%;
}

.password-eye-pass {
    position: relative;
	top: -32px;
    z-index: 5000;
    left: 45%;
}


@media(min-width: 1366px) {

}

@media(max-width: 1366px) {
    .auth-container{
        width: 90%;
    }
    .auth-bg-container {
        width: 90%;
        height: 90%;
    }
    .auth-form-container {
        width: 90%;
    }
}

@media(max-width: 1200px){
    .auth-container{
        width: 95%;
    }

    .auth-subheading {
        width: 100%;
    }

    .auth-form-container{
        width: 95%;
    }
    .auth-flex {
        gap: 52px;
    }
}

@media(max-width: 991px) {}

@media(max-width: 768px) {
    .auth-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        gap: 0px;
        /* width: 10vw; */
    }
    .auth-container {
        width: 100%;
    }
    .auth-flex {
        gap: 0px;
    }
    .auth-form-container {
        width: 95%;
        margin: auto;
    }
    .auth-bg-container{
        display: none;
    }
}
@media (max-width: 665px){
    .popup-content{
        width: 80%;
    }
}
@media (min-width: 666px){
    .popup-content{
        width: 616px;
    }
}

@media(max-width: 450px) {
    .auth-form-container {
        padding: 24px 12px;
    }
    .verif h4{
        font-size: 1rem;
    }
    .popup-content{
        padding: 10px 14px;
    }

}
/* For VerificationSuccess Page */
.overlay {
    background-color: #F5F6F7;
}
.verif{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.verif h4{
    margin: 0px 0px;
    font-weight: 700;
}
.popup-content p{
    text-align: left;
    font-size: 18px;
    line-height: 25px;
    margin-block: 3px;
    color: #667185;
}
.pop-contain{
    max-width: 700px;
    padding-inline: 4% 4%;
}
.popup-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* line-height: 33.6px; */
    background: #FFFFFF;
    padding: 14px 28px;
    border-radius: 8px;
    min-width: 300px;
}
.popup-content .auth-create-button{
    margin-top: 5px;
}