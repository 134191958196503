.headeritems{
    display: flex;
    justify-content: space-between;
    padding-block: 16px;
    background: #F5F6F7;
    padding-inline: 2.3% 5%;
    cursor: pointer;
}
h3{ 
    font-weight: 500;
}
.mainitems{
    margin-block: 24px;
    border-radius: 8px;
    border: 1px solid #dfdede ;
    text-align: left !important;
}
.forPricing{
    background: #FFFF;
    padding-inline: 2.3% 5.4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    padding-block: 16px;
}
.noMargin{
    margin: 0;
}
.forAmount{
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #40444B;
}
.forPricing p{
    color: #667185;
    font-size: 12px;
    font-weight: 400;
}