/* For WelcomeToShelf MOdal */
.overlay {
    background-color: #F5F6F7;
}
.verif{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.verif h4{
    margin: 0px 0px;
}
.verify-content p{
    text-align: left;
    font-size: 14px;
    line-height: 25px;
    margin-block: 3px;
    color: #667185;
}
.pop-contain{
    max-width: 700px;
    padding-inline: 4% 4%;
}
.verify-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* line-height: 33.6px; */
    background: #FFFFFF;
    padding: 14px 28px;
    border-radius: 8px;
    min-width: 300px;
}
.nav-to .btn-item{
    width: 100%;
}
.verify-content .auth-create-button{
    margin-top: 5px;
    
}
.verify-content .outline{
    background: transparent;
    border: 1.2px solid rgba(15, 151, 61, 1);
    color: rgba(15, 151, 61, 1);
}

.verify-content .nav-to{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
}