/* .orgchart.myChart {
    background-image: linear-gradient(
        90deg,
        rgba(33, 90, 136, 0.15) 10%,
        rgba(0, 0, 0, 0) 10%
      ),
      linear-gradient(rgba(33, 90, 136, 0.15) 10%, rgba(0, 0, 0, 0) 10%) !important;
  } */

  .orgchart {
    background-image: none;
  }
  
  .orgchart.myChart > ul > li > ul li::before {
    border-top-color: #215a88;
  }

  .orgchart-container {
    border: none
  }
  
  .orgchart.myChart > ul > li > ul li .oc-node::before,
  .orgchart.myChart ul li .oc-node:not(:only-child)::after {
    background-color: #215a88;
  }
  
  .orgchart.myChart .oc-node .position {
    box-sizing: border-box;
    background-color: none;
    /* color: #0065FF; */
    font-weight: bold;
  }

  .orgchart ul li .oc-node.selected {
    background-color: #E6F0FF;
  }

  .orgchart ul li .oc-node:hover {
    background-color: #E6F0FF;
  }
  
  .orgchart.myChart .oc-node .fullname {
    box-sizing: border-box;
    /* color: #0065FF; */
    background-color: #fff;
    width: 130px;
    height: 65px;
    padding: 2px;
    /* border: 1px solid #215a88; */
    width: 142px;
    height: 58px;
    padding: 2px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  