.pay_redirect{
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pay_redirect div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pay_redirect div p{
    margin: 0 !important;
font-size: 16px;
font-weight: 500;


}

.pay_redirect a{
    padding: 10px 2rem;
    background: #0065FF;
    color: white;
    border-radius: 32px;
    text-decoration: none;
    margin-top: 1rem;
}