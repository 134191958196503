.allcontent{
    text-align: left;
    padding: 0px !important;
}
.changepassword {
    font-size: 16px;
    font-weight: 700;
    margin-block: 6px;
}
.passwordtext {
    font-size: 14px;
    color: #667185;
    margin-block: 0px;
}
.people{
    font-size: 16px;
    font-weight: 800;
    line-height: 19.2px;
    letter-spacing: -2%;
    margin-block: 6px;
}
.dashcontent{
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -2%;
    color: #667185;
    margin-block: 8px 8px;
}
.optioninput{
    margin-left: 16px;
    margin-top: 16px;
}
.pagecontent{
    margin-block: 1.3rem;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px 20px;
}
.picks{
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -2%;
    color: #667185;
    cursor: pointer;
    gap: 4px;
}
/* .copyinput {
    position: relative;
} */
.copyicon{
    position: absolute;
    color: black;
    right: 35px;
    top: 48%;
}

@media(max-width: 600px){
    .pagecontent{
        padding: 25px 10px;
    }
}