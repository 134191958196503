.eventlink {
    text-decoration: none;
}

.evntcardcont {
    position: relative;
    max-width: 475px;
    height: 290px;
    border-radius: 12px;
    border: 1px solid #EDEDF2;
    text-decoration: none;
    background: white;
}
.evntcard {
    padding: 16px;
}
.imageheader {
    height: 100px;
    overflow: hidden;
}
.imageheader img {
    width: 100%;
    border-radius: 12px 12px 0px 0px;
}
.evntcard h6 {
    font-weight: 700;
    font-size: 16px;
    margin-block: 0px;
    text-align: left;
    text-decoration: none;
}
.evntcard p {
    font-weight: 400;
    font-size: 16px;
    height: 50px;
    color: #8C94A6;
    max-width: 405px;
    margin-block: 8px;
    text-align: left;
    text-decoration: none;
}
.evntcard a {
    font-weight: 500;
    font-size: 16px;
    line-height: 23.2px;
    color: #0A17A7;
    text-align: left !important;
    text-decoration: none;
}
.evntinfo {
    text-align: left;
}
.loweritems {
    height: 50px;
}
@media (max-width: 600px) {
    .evntcardcont {
        margin-top: 16px;
    }
}