.errorwrapper {
    width: 100%;
    height: 100vh;
    background-color: white!important
}

.exclamationcircle {
    width: 400px;
    height: 400px;
    background-color:#0065FF;
    border-radius: 50%;
    z-index:0;
    margin-top:5%;
}

.errorcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.backgroundclip {
    position: absolute;
    top: 30%;
    z-index: 1;
    background-color: rgba(0,0,0, 0.05);
    backdrop-filter: blur(15px);
    left:0;
    right:0;
    bottom: 0;
}

.exclamationmark {
    z-index: 3;
    font-size: 550px;
    position: absolute;
    top: -60%;
    left: 0;
    right: 0;
    color: white;
}

.textcontainer {
    position: relative;
    margin:auto;
    z-index: 5;
    font-size: 40px;
    text-align: center;
    margin-top:80px;
    width: 433px;

}

.textbold {
font-size: 32px;
letter-spacing: -1.5px;
color: black;
font-weight:500;
}

.textsubtitle {
font-size: 16px;
color: "#475367";
line-height: 18px;
letter-spacing: -0.5px;

}

.homelink {
    font-size: 18px;
    text-decoration: none;
    color:#0065FF;
    letter-spacing: -0.5px;
    font-weight:bold;
}

.logocontainer {
    width: 130px;
    height: 43px;
}

@media(max-width: 576px){
    .exclamationcircle {
        width: 300px;
        height: 300px;
        margin-top:30%;
    }

    .exclamationmark {
        font-size: 300px;
        top:10%;
    }

    .textcontainer {
        margin-top:50px;
        width: 330px;
    
    }

    .textbold {
        font-size: 24px;
        }
        .textsubtitle {
            font-size: 14px;
            color: "#475367";
            line-height: 16px;
            letter-spacing: -0.5px;
            
            }
        .homelink {
            margin-bottom: 0px;
        }
}