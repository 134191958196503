.erroritems{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 620px;
    padding-bottom: 50px;
    /* padding-top: 150px; */
    /* height: 450px; */
    margin:auto;
}

.errorspan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.subtitle {
    color: rgba(71, 83, 103, 1);
    font-size: 14px;
    font-weight: 400;
    margin-top: 0px;
}

.title {
    color: black;
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.title h4 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.title h1 {
    margin-bottom: 0px;
    margin-top: 0px;
}


.subtitle p {
    margin-bottom: 2px;
    margin-top: 2px;
}

.errorimg {
    width: 200px;
    height: 150px;
}

@media(max-width: 768px){
    .erroritems{
        max-width: 80%;
        /* height: 400px; */
    }
}

@media(max-width: 576px){
    .erroritems {
        max-width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}